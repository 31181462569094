import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'tr[ccTr]',
  template: ` <ng-content></ng-content>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrComponent {
  @Input('ccClickable')
  clickable = false;

  @Input('ccSelected')
  selected = false;

  @Input('ccDisabled')
  disabled = false;

  @HostBinding('class')
  get hostClass(): string {
    const classes: string[] = [];

    if (this.clickable) {
      classes.push('cc-tr--clickable');
    }

    if (this.selected) {
      classes.push('cc-tr--selected');
    }

    if (this.disabled) {
      classes.push('cc-tr--disabled');
    }

    return classes.join(' ');
  }
}
